<template>
  <div>
    <title>SIM INVENTORY ~ UPDATE MASTER BUYER DATA</title>
    <section class="content-header">
      <h1>
        Update Master Buyer Data
        <br />
        <h4>Please Update Master Buyer Data</h4>
      </h1>
      <ol class="breadcrumb">
        <li>
          <a href="#"><i class="fa fa-dashboard"></i> Home</a>
        </li>
        <li class="active">Update Master Buyer Data</li>
      </ol>
    </section>
    <section class="content">
      <div v-if="loading" class="load">
        <img width="130" src="@/assets/loader.gif" alt="" />
      </div>
  
      <div class="row">
        <!-- left column -->
        <div class="col-md-12">
          <!-- general form elements -->
          <div class="box box-primary">
            <div class="box-header with-border">
              <h3 class="box-title">Data buyer</h3>
            </div>
            <!-- /.box-header -->
            <!-- form start -->
            <form role="form">
              <div class="box-body">
                <div class="form-group">
                  <label for="exampleInputEmail1">Buyer Code</label>
                  <input
                    type="text"
                    v-model="kodebuyer"
                    autocomplete="off"
                    class="form-control"
                  />
                  <input
                    type="hidden"
                    v-model="idbuyer"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Buyer Name</label>
                  <input
                    type="text"
                    v-model="namabuyer"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Description</label>
                  <input
                    type="text"
                    v-model="notesbuyer"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
              </div>

              <div class="box-footer">
                <button
                  @click="updateData()"
                  type="button"
                  class="btn btn-primary"
                ><i class="fa fa-save"></i>
                  Update
                </button>
                &nbsp;
                <router-link to="/buyer">
                  <button class="btn btn-success"><i class="fa fa-angle-double-left"></i> Back</button>
                </router-link>
              </div>
            </form>
          </div>
        </div>
      
      </div>
    </section>
  </div>
</template>
<script>
import swal from "sweetalert";
var axios = require("axios");
export default {
  name: "editmasterdata",
  data() {
    return {
      isInputActive: false,
      loading: false,
      kodebuyer: "",
      namabuyer: "",
      notesbuyer: "",
      idbuyer: ""
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.loading = true;
      // const tokenlogin = localStorage.getItem("token");
      const tokenlogin = sessionStorage.getItem("token");
      // const urlAPIget = "http://26.183.23.191/inventory/backend/inventory/api/buyer/getbuyerbyid?id=" + this.$route.params.id;
      const urlAPIget =
        this.$apiurl + "buyer/getbuyerbyid?id=" + this.$route.params.id;
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers })
        .then((resp) => {
          this.idbuyer = resp.data.data.id;
          this.kodebuyer = resp.data.data.kode_buyer;
          this.namabuyer = resp.data.data.name;
          this.notesbuyer = resp.data.data.notes;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async updateData() {
      this.loading = true;
      // alert(this.$route.params.id);
      var kode = this.kodebuyer;
      var nama = this.namabuyer;
      var notes = this.notesbuyer;
      var idx = this.idbuyer;
      if (kode == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Buyer Code can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (nama == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Buyer Name can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (notes == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Description can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else {
        const kodeuser = sessionStorage.getItem("kodeuser");
        const paramdata = {
          kode_buyer: this.kodebuyer,
          name: this.namabuyer,
          notes: this.notesbuyer,
          kode_user: kodeuser
        };
        // const tokenlogin = localStorage.getItem("token");
        const tokenlogin = sessionStorage.getItem("token");
        // const urlAPIUpdate = "http://26.183.23.191/inventory/backend/inventory/api/buyer/updatebuyer/" + idx;
        const urlAPIUpdate = this.$apiurl + "buyer/updatebuyer/" + idx;

        const headers = {
          Accept: "application/json",
          Authorization: tokenlogin
        };
        axios
          .put(urlAPIUpdate, paramdata, { headers: headers })
          .then((resp) => {
            // console.log(resp);
            if (resp.data.status == true) {
              swal({
                icon: "success",
                title: "Success",
                text: "Update data successfully",
                showConfirmButton: false
              });
              this.loading = false;
              this.$router.push({ name: "buyer" });
            } else {
              swal({
                icon: "warning",
                title: "Warning",
                text: "Update data failed",
                showConfirmButton: false
              });
              this.loading = false;
            }
          })
          .catch((err) => {
            swal({
              icon: "warning",
              title: "Warning",
              text: err.response.data,
              showConfirmButton: false
            });
            console.log(err);
            this.loading = false;
          });
      }
    }
  }
};
</script>
<style>
.load {
  width: 20%;
  margin: 0 auto;
}
</style>
